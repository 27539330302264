export default {
    created() {
      },
    mounted() {

    },
    data() {
        return {
        };
    },
    methods: {

    }
}
